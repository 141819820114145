@font-face {
  font-family: myFirstFont;
  src: url(../../font/AlegreyaSansSC-Italic.ttf);
}
.sidebar {
  width: 220px;
  overflow: hidden;
  overflow-y: auto;
  transition: 0.5s;
  box-shadow: 0 0 40px #eff1f7;
  overflow-x: hidden;
}

.sidebar_collapsed {
  width: 80px;
}

.sidebar::-webkit-scrollbar {
  width: 6px;
}

.sidebar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #117aa7;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: #118abe;
}

.sidebar .logo img {
  width: 72%;
  margin-top: 67px;
  margin-left: 14%;
  margin-bottom: 18%;
}
.sidebar #sidebar_menu li a {
  font-size: 15px;
  font-weight: bold;
  color: rgb(103, 107, 132);
  padding: 2% 0px 4% 10%;
  z-index: 0;
  display: grid;
  grid-template-columns: 7% auto;
  gap: 15px;
  align-items: center;
  position: relative;
  background: transparent;
  text-decoration: none !important;
  transition: all 0.5s ease 0s;
  font-family: myFirstFont;
  letter-spacing: 1px;
}
.sidebar #sidebar_menu li:hover {
  background: linear-gradient(
    45deg,
    #117aa7,
    #117aa785,
    white,
    transparent,
    transparent,
    transparent
  );
}
.sidebar ul,
ol {
  margin: 0 !important;
  padding: 0 !important;
}
li {
  list-style: none;
}
.sidebar li {
  margin-bottom: 0.5px;
}

@media (max-width: 1150px) {
  .sidebar .logo {
    padding: 30px;
  }

  footer {
    bottom: 1%;
    left: 1%;
  }
}

.sidebar_close_icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  position: absolute;
  right: 30px;
  font-size: 25px;
  top: 29px;
  cursor: pointer;
  color: #222222;
}

.ti-close:before {
  content: "\e646";
}
@media (max-width: 1200px) {
  .active_sidebar {
    left: 0;
    box-shadow: 0 5px 20px rgb(0 0 0 / 20%);
  }
}

.sidebar #sidebar_menu .activated {
  color: rgb(103, 107, 132) !important;
  background: linear-gradient(
    45deg,
    #117aa7,
    #117aa785,
    white,
    transparent,
    transparent,
    transparent
  );
}
.sidebar #sidebar_menu .Sousactivated {
  color: rgb(103, 107, 132) !important;
  background: linear-gradient(45deg, #117aa7, #117aa785, white, white);
}
.sidebar #sidebar_menu a .activatedSubList {
  color: #117aa7 !important;
}
