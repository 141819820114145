* {
  box-sizing: border-box;
}

body {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 14px !important;
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
  overflow-y: hidden !important;
}

.main_content {
  width: 100%;
  padding-top: 0px !important;
  transition: 0.5s;
  position: relative;
  background-color: #f4f7fc;
}

.box {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.box_main,
.box_content,
.box_content_mobile {
  width: 100%;
  height: 100%;
}

.box_content_mobile {
  height: calc(100vh - (2.3rem + 2rem));
}

.box_content_desktop_expanded,
.box_content_desktop {
  height: calc(100vh - 62px);
}

.box_content_desktop {
  margin-left: 220px;
  transition: ease 0.5s;
}

.box_content_desktop_expanded {
  margin-left: 85px;
  transition: ease 0.5s;
}

.box_content_mobile
  .css-1tw7cpj-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {
  min-width: 20px !important;
}

.box_sideBar {
  flex: 0 0 auto;
  transition: flex 0.3s;
  max-height: 100vh;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}

.box_header {
  height: 30px;
  margin-bottom: 2rem;
}

.box_container {
  display: flex;
  /* max-width: 2000px;
  width: 100%; */
  width: 100vw;
  height: 100vh;
}

.box_content_desktop_expanded h3,
.box_content_desktop h3 {
  font-weight: 600;
  font-size: 17px;
}

.box_content_desktop_expanded h4,
.box_content_desktop h4 {
  font-weight: 600;
  font-size: 22px;
}

@media (max-resolution: 94dpi) {
}
/* Styles pour une échelle de 125% */
@media (min-resolution: 120dpi) and (max-resolution: 140dpi) {
}

/* Styles pour une échelle de 150%*/
@media (min-resolution: 141dpi) and (max-resolution: 160dpi) {
}

/* Styles pour une échelle de 175% */
@media (min-resolution: 161dpi) {
  .box_header {
    height: 30px;
  }
  .box_content_mobile {
    height: calc(100vh - (80px + 2rem));
    overflow-y: auto;
    scroll-behavior: smooth;
  }
  .box_content_mobile h3 {
    font-size: 15px;
  }
  .box_content_mobile h4 {
    font-size: 1.2rem;
  }
  .box_content_mobile strong {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .box_header {
    height: 30px;
    margin-bottom: 4rem;
  }
  .box_content_mobile {
    height: calc(100vh - (80px + 2rem));
    overflow-y: auto;
    scroll-behavior: smooth;
  }
}
