.LabelsInfo {
  width: 79%;
  margin-bottom: 240px;
  float: right;
}
.LabelsTask {
  margin-bottom: 240px;
  width: 1061px;
}

@media (min-width: 2000px) {
  .LabelsInfo {
    padding-left: 10%;
  }
}
.Candidatlabel:hover,
.Candidatlabel::after {
  background-color: white;
  color: rgb(17, 122, 167);
  font-size: 15px;
}
.contentWithHeadSelectionClosed {
  height: calc(100vh - 159px);
}
.contentWithHeadSelectionOpened {
  height: calc(100vh - 212px);
}

.candidadat_number_date_updated_zoom {
  display: flex;
  gap: 10px;
  font-weight: 600;
  align-items: baseline;
  font-family: "StyleFont";
}
.content_police_990px_1024px {
  font-size: 1.3rem;
}

.buttonIcons .MuiButton-root {
  min-width: 55px;
}

@font-face {
  font-family: StyleFont;
  src: url(../../font/AlegreyaSansSC-Italic.ttf);
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .contentWithHeadSelectionClosed {
    height: calc(100vh - 19rem);
  }
  .contentWithHeadSelectionOpened {
    height: calc(100vh - 14.6rem);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .contentWithHeadSelectionClosed {
    height: calc(100vh - 249px);
  }
  .contentWithHeadSelectionOpened {
    height: calc(100vh - 300px);
  }
}

@media only screen and (width: 1024px) {
  .contentWithHeadSelectionClosed {
    height: calc(100vh - 198px);
  }
  .contentWithHeadSelectionOpened {
    height: calc(100vh - 249px);
  }
}
