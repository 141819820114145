.inputs-user-mission {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}
.inputs-user-mission:focus {
  background-color: orange;
  outline: none;
}
.form-container {
  padding: 10px 0;
}
.registerbtn {
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}
.registerbtn:hover {
  opacity: 1;
}

.addUser_container {
  overflow-y: auto;
  height: calc(100vh - 113px);
  padding: 1px 3px 10px 1px;
  overflow-x: hidden;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .addUser_container {
    height: calc(100vh - 10rem);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .addUser_container {
    height: calc(100vh - 10.5rem);
  }
}
